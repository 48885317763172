.cardicontexttitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFFFFF;
}
.cardicontextbody {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    text-transform: initial;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    margin-bottom:80px;
}