.cuerposervicios {
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #111010;
    text-transform: uppercase;
    
}
.serviciostitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #FAFAFA;
    padding-bottom: 30px;
}