.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
}
.subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    color: #383838;
    /* color: #fff; */
    
}
.containeraboutbody {
    padding-top: 100px;
    padding-bottom: 50px;
}
.containeraboutbodyoutter {
    color: #383838;
    background-color: #fff;
}
.subcontaineraboutbody {
    padding-top: 50px;
    padding-bottom: 50px;
}

.about-top-right-frame {
    position: absolute;
    right: 0%;
    top: 5em;
    width: 215px;
    height: 70px;
    z-index: 1;
}
.about-bottom-left-frame {
    position: absolute;
    left: -100px;
    bottom: 5em;
    width: 215px;
    height: 70px;
    z-index: 1;
}
.about-bottom-left-frame2 {
    position: absolute;
    left: -100px;
    bottom: 5em;
    width: 215px;
    height: 70px;
    z-index: 1;
}
.about-container {
    position: relative;
    color: #111010;
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    animation-name: on-appear;
    animation-duration: 2s;
}
.about-container-separator {
    margin-bottom: 150px;
}