.OButton {
    padding: 12px 48px;
    background: linear-gradient(261.15deg, #FF6412 65.62%, #FF9400 109.75%);
    border-radius: 24px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    width: 160px;
/* identical to box height, or 136% */
    color: #f1efef;
    border: none;
    transition-duration: 0.4s;
}
.OButtonInactive {
    padding: 12px 48px;
    background: #ffffff00;
    border-radius: 24px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    
    /* width: 80px; */
/* identical to box height, or 136% */
    color: #383838;
    border: none;
    transition-duration: 0.4s;
}
.OButtonText {
    /* width: 149px;
    height: 19px; */

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
/* identical to box height, or 136% */
    text-transform: uppercase;
    color: #ffffff;
}

/* button:hover {
    background-color: #d6097d;
}
button:active {
    background-color: #c01f7a;
    box-shadow: 2 2px rgb(94, 14, 78);
    transform: translateY(4px);
} */