.titulo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}
.subtitulo {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.descripcion {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.cuerpo {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 30px;
    border-width: 0px;
    border-color: #fff;
    background-color: #fff;
    border-style: inset;
    border-radius: 20px;
    text-align: left;
    shape-outside: margin-box;
    transition-duration: 0.4s;
    /* color: #000; */
    /* color: #fff; */
}
.cuerpo:hover {
    box-shadow: 0px 0px 1px 1px #a4a3a3;
    /* color: #111010; */
}
.compactpadding {
    padding-top: 5px;
}
.webiconcontainer {
    display: inline-block;
}
.integranteBoxImagen {
    min-width: 180px;
    min-height: 180px;
}
.logoImagen {
    border-radius: 50%;
    min-width: 180px;
    min-height: 180px;
}
.logoIcono {
    width: 30px;
    height: 30px;
}