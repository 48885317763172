.logosBackground {
    background-color: #FFBC02;
    padding-top: 50px;
    padding-bottom: 100px;
    padding-left: 20%;
    padding-right: 20%;
}

.logostitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
}

.logoimage {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
}

.logosbox {
    padding-top: 50px;
    padding-bottom: 30px;
}