.newlettercomponent {
    background-color: #FFBC02;
    padding-top: 100px;
    padding-bottom: 100px;
    /* padding-left: 20px;
    padding-right: 20px; */
    position: relative;
}
.newletterform {
    width: 300px;
    align-items: center;
}
.newsinputform {
    width: 300px;
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    border-radius: 20px;
    border-color: #fff;
    border-width: 0px;
    font-size: 16px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.newsinputform:focus, .newsinputform:focus{
    outline: none;
}
.newstitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.08em;
    color: #383838;
}
.newsmessage {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #383838;
}
.newsdescription {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #383838;
}

.news-top-left-frame {
    position: absolute;
    width: 387;
    height: 262;
    left: 0%;
    top: 0em;
    /* z-index: -2; */
}
.news-bottom-right-frame {
    position: absolute;
    width: 426;
    height: 262;
    right: 0%;
    bottom: 0em;
    /* z-index: -2; */
}
.news-container {
    /* position: relative; */
    padding-left: 20px;
    padding-right: 20px;
}