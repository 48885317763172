.equipamientoBackground {
    background-color: #1e1e1e;
    padding-top: 100px;
    padding-bottom: 50px;
    
}
.equipamiento-box {
    /* width: 60%; */
    min-width: 300px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
}

.equipamientoTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    text-align: center;
}
.equipamientoSubTitle {
    font-family: 'Montserrat';
    font-family: 'Montserrat';
    text-align: left;
    padding-bottom: 40px;
    padding-left: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.equipamientoImage {
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 30px;
    align-self: left;
    /* float: left; */
}
.equipamiento-container {
    text-align: left;
}
.equipamiento-button-container {
    text-align: center;
    
}