.textAreaBackGround {
    background-color: #1E1E1E;
    padding-top: 100px;
    padding-bottom: 100px;
    /* margin-top: -5px; */
}

.textArea {
    /* width: 66%; */
    padding-top: 100px;
    padding-bottom: 60px;
    border-radius: 4px;
    background-color: #fafafa;
}

.textAreaText {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;
}