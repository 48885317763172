.contactcomponent {
    background-color: white;
    padding-top: 0px;
    padding-bottom: 10px;
}
.contactformbox {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 5px #eeeeeebb; */
    align-content: left;
    position: relative;
}
.contact-redes {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    align-content: left;
}
.contact-redes-box {
    background-color: #fff;
    align-self: left;
    padding-top: 10px;
    padding-bottom: 10px;
    align-content: left;
    text-align: center;
}
.contactinputform {
    
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    border-radius: 20px;
    border-color: #cccccccc;
    border-width: 1px;
    border-style: double;
    font-size: 16px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* box-shadow: 0px 0px 10px 5px #eeeeeecc; */
}
.contactinputsmallform {
    /* margin-right: 20px; */
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    border-radius: 20px;
    border-color: #ccc;
    border-width: 1px;
    border-style: double;
    font-size: 16px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.separatorhorizontal {
    margin-left: 20px;
}
.contactinputdetailform {
    height: 200px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    border-radius: 20px;
    border-color: #ccc;
    border-width: 1px;
    border-style: double;
    font-size: 16px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.contactinputdetailform:focus, .contactinputsmallform:focus, .contactinputform:focus{
    outline: none;
}
.buttonContainer {
    margin-top: 20px;
    align-items: right;
    align-self: right;

}
.contactTitle {
/* Desktop/H4 */
text-align: left;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 26px;
/* identical to box height, or 217% */

letter-spacing: 0.08em;

/* Color Copy */

color: #383838;
}
.contactTitle2 {
    /* Desktop/H4 */
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    /* identical to box height, or 217% */
    
    letter-spacing: 0.08em;
    
    /* Color Copy */
    
    color: #383838;
    }
.contactSubtitle {
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    
    /* Amarillo 811 */
    
    color: #FFBC02;
    
}
.contactDescription {
    /* Desktop/body regular */
    text-align: left;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    
    
    /* Color Copy */
    
    color: #383838;
}
.contact-top-right-frame {
    position: absolute;
    right: -8px;
    top: 5em;
    width: 131px;
    height: 57;
    /* z-index: -2; */
}
.contact-bottom-left-frame {
    position: absolute;
    left: 0%;
    bottom: 1em;
    width: 131px;
    height: 57;
    /* z-index: -2; */
}
.contact-container {
    position: relative;
}
.mapabox {
    height: 640;
    width: 640;
    min-width: 320;
    min-height: 320;
}