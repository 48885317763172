.navbarBody {
    /* background-color: #111010; */
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    align-content: initial;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.navbar-popup-body {
    padding-top: 30px;
    padding-bottom: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    /* bottom: 0; */
    /* height: 500px; */
    background-color: #000;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    z-index: 2;
}
.navbar-popup-container {
    position: relative;
    color: #000;
    
}
.nav-link {
    font-size: 16px !important;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
}
.navbar-brand {
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
}
.navbar-button {
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 10px;
    margin-top: 10px;
    position: absolute;
    top: 5px;
    right: 30px;
    width: 15px;
    height: 15px;
    background-color: #ffffff00;
    border-style: initial;
    z-index: 5;
}

.navbar-container {
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    align-self: center;
}
.navbar-popup-body .navbar-brand {
    padding-top: 20px;
    padding-left: 30px;
}
.navbar-link {
    /* position: absolute; */
    /* width: 237px;
    height: 76px;
    left: 304px;
    top: 239px; */

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    color: #FFFFFF;
    text-decoration: none;
}
.navbar-link:link { 
    text-decoration: none; 
    color: #FFFFFF;
}


.navbar-link:visited { 
    text-decoration: none; 
    color: #FFFFFF;
}


.navbar-link:hover { 
    text-decoration: none; 
    color: #CCCCCC;
}


.navbar-link:active { 
    text-decoration: none; 
    color: #FFFFFF;
}