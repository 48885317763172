.packcomponenttextbox {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}
.packcomponenttitle {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #383838;
    text-align: left;
}
.packcomponentdescription {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #383838;
    text-align: left;
}
.pack-top-right-frame {
    position: absolute;
    right: 0%;
    top: 0;
    z-index: -2;
}
.pack-bottom-left-frame {
    position: absolute;
    left: 0%;
    bottom: 0;
    z-index: -2;
}
.pack-container {
    position: relative;
}