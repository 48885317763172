.mediaComponent {
    background-color: #1e1e1e;
    padding-top: 50px;
    padding-bottom: 50px;
}

.mediaBody {
    padding-top: 100px;
    padding-bottom: 60px;
    border-radius: 4px;
    background-color: #fafafa;
    
}
.mediatitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    /* identical to box height, or 217% */
    letter-spacing: 0.08em;
    color: #1e1e1e;
}
.player {
    margin-left: 25%;
    margin-right: 25%;
    display: block;
    /* width: 450px; */
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
    
  }