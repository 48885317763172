.reviews-body {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #e6e6e6;
}

.reviews-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #383838;
    padding-bottom: 30px;
}

.review-body {
    /* height: 400px;
    width: 400px; */
    /* border-style: dashed;
    border-width: 1px;
    border-color: #383838; */
    /* box-shadow: 0px 5px 5px 0px #8e8c8c; */
    /* align-self: center; */
    background-color: #fff;
    border-radius: 10px;
    border-width: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    /* width: 50%; */
    /* margin-left: 20%;
    margin-right: 20%; */
    background-position: center;
    background-size: cover;
    /* width: 500px; */
    display: block;
    width: 100%;
    /* height: 300px; */
}
.review-subbody {
    margin-left: 24px;
    margin-right: 24px;
}
.review-coma {
    margin-top: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 24px;
    color: #FF6412;
}
.review-description {
    margin-top: 30px;
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #383838;
}
.review-author {
    /* margin-top: 30px; */
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #383838;
}
.review-song {
    /* margin-top: 30px; */
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FF6412;
}
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    /* height: 300px; */
  }
.review-author:link { 
    text-decoration: none; 
    color: #383838;
}


.review-author:visited { 
    text-decoration: none; 
    color: #383838;
}

.review-author:hover { 
    text-decoration: none; 
    color: #383838;
}

.review-author:active { 
    text-decoration: none; 
    color: #383838;
}