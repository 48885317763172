.studiotitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    text-align: left;
    padding-left: 10px;
    /* color: #383838; */
}
.studiodescription {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    text-align: left;
    padding-left: 10px;
    color: #383838;
}
.cardview {
    padding: 5;
    border-width: 3px;
    border-left: #cecece;
    transition-duration: 0.4s;
    color: #383838;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-bottom: 30px; */
    margin-bottom: 80px;
}
.cardview:hover {
    border-width: 3px;
    border-radius: 4px;
    box-shadow: #c2710d 0 0 0px !important;
    border-left: 4px solid #FF9400 !important;
    color: #FF9400;
}