.packchooserbox {
    border-width: 1px;
    border-style: initial;
    border-color: blanchedalmond;
    box-shadow: 0px 0px 10px 5px #eeeeeebb;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.packchooserbuttonbox {
    border-width: 1px;
    border-style: initial;
    border-color: blanchedalmond;
    box-shadow: 0px 0px 10px 5px #eeeeeebb;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 40px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}
.packtext {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #383838;
}
.packtextDeselect {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8e8e8e;
    text-decoration:line-through;
}
.iconSelected {
    color:gold;
    font-size: 16px;
}
.icondeselected {
    color:rgb(251, 229, 106);
    font-size: 16px;
}
.buttonHolder {
    margin-top: 20px;
    margin-bottom: 20px;
}