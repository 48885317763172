.SButton {
    padding: 12px 48px;
    background-color: #ff9400;
    border-radius: 24px;
    /* font-family: 'Nunito'; */
    font-family: 'Montserrat';
    /* -webkit-text-stroke: 0.2px black; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
/* identical to box height, or 136% */
    text-transform: uppercase;
    color: #fff;
    border: none;
    transition-duration: 0.4s;
}
.SButtonDisabled {
    padding: 12px 48px;
    background-color: #9e7f90;
    border-radius: 24px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
/* identical to box height, or 136% */
    text-transform: uppercase;
    color: #f1efef;
    border: none;
    transition-duration: 0.4s;
}
.SButtonText {
    /* width: 149px;
    height: 19px; */

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
/* identical to box height, or 136% */
    text-transform: uppercase;
    color: #ffffff;
}

.SButton:hover {
    background-color: #db1491;
    color: #f1efef;
    /* border-style: inset;
    border-width: 0.3;
    border-color: #000; */
}
.SButton:active {
    background-color: #FF0190;
    box-shadow: 2 2px rgb(94, 14, 78);
    transform: translateY(4px);
}