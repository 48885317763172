.footer-body {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -70px;
    height: 120px;
    /* bottom: 0; */
    /* height: 500px; */
    background-color: #000;
    padding-top: 5px;
    padding-bottom: 5px;
    /* display: inline-block; */
    /* z-index: 2; */
}
.footer-body .navbar-brand {
    padding-top: 10px;
    padding-left: 10px;
}
.footer-redes {
    position: absolute;
    top: 40px;
    right: 20px;
}
.footer-redes-social {
    display: inline-block;
}
.center-body {
    position: absolute;
    top: 40px;
    bottom: 20px;
    left: 30%;
    right: 30%;  
    list-style-type: none;

  }
.footer-redes-text {

/* Common/label */
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height, or 119% */
    color: #FFFFFF;
    text-decoration: none; 
    /* display: block; */
}
.footer-redes-text-link {

    /* Common/label */
    
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        /* identical to box height, or 119% */
        color: #FFFFFF;
        text-decoration: none; 
    }
.footer-redes-text-link:link { 
    text-decoration: none; 
    color: #FFFFFF;
}


.footer-redes-text-link:visited { 
    text-decoration: none; 
    color: #FFFFFF;
}


.footer-redes-text-link:hover { 
    text-decoration: none; 
    color: #CCCCCC;
}


.footer-redes-text-link:active { 
    text-decoration: none; 
    color: #FFFFFF;
}
.footer-red {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
}
