.galleryComponent {
    background-color: #1e1e1e;
    padding-top: 50px;
    padding-bottom: 50px;
}

.gallerytitle {
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 26px;
/* identical to box height, or 217% */

letter-spacing: 0.08em;

color: #FFFFFF;

}

.gallerysubtitle {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 217% */
    
    letter-spacing: 0.08em;
    
    color: #FFFFFF;
}