.studioPrimaryText {
    font-family: 'Light';
    
    /* font-style: normal; */
    /* font-weight: 500; */
    /* font-size: 1.875em; */
    /* letter-spacing: 10; */
    font-size: 24px;
    line-height: 70px;
    -webkit-text-stroke: 0.3px black;
    /* border: ; */
    /* margin-bottom: -30; */
    /* letter-spacing: 0.01em; */
    
}
.studioLabelText {
    font-family: 'Light';
    /* font-style: normal; */
    /* font-weight: 600; */
    -webkit-text-stroke: 0.5px black;

    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 200% */
    
    letter-spacing: 0.08em;
}
.carrouselseparation {
    padding-bottom: 50;
}

#img1 {display:block;}
#img2 {display:none}

@media all and (max-width: 499px) {
    #img1 {display: none;}
    #img2 {display: block;}
}