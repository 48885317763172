.dropdownBox {
    background-color: #fafafa;
    /* width: 60%; */
    min-width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: center;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
    padding-left: 30px;
    position: relative;
    
}

.dropdownTitle {
    text-align: left;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
/* or 119% */


/* Color Copy */

    color: #383838; 
}
.dropdownItem {
    /* Desktop/body regular */
    text-align: left;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    color: #383838;
}

.orangeSeparator {
    color: #ff6412;
}

.dropDownArrow {
    position: absolute;
    right: 10px;
    top: 15px;
    transition-duration: 1s;
}